<template>
  <div class="pay_container">
    <div>支&nbsp;付&nbsp;中...</div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Pay",
  data () {
    return {
      patCardNo: "",
      outTradeNo: "",
      count: 0
    };
  },
  created () {
    this.patCardNo = this.$route.query.patCardNo;
    this.outTradeNo = this.$route.query.outTradeNo;
    this.getPayStatus();
  },
  methods: {
    //查询支付状态
    async getPayStatus () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        outTradeNo: this.outTradeNo
      });
      const { data: res } = await formPost(
        "/wxapp/pay/orderRecord/getOrderPayStatus",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        if (this.count < 3) {
          if (res.data.isPay === 0 || res.data.isPay === 7) {
            setTimeout(() => {
              this.count = this.count + 3;
              this.getPayStatus();
            }, 4000);
          } else {
            window.localStorage.setItem("outTradeNo", this.outTradeNo)
            this.$router.push({
              path: "/outpatient/status",
              query: {
                outTradeNo: this.outTradeNo
              }
            });
          }
        } else {
          window.localStorage.setItem("outTradeNo", this.outTradeNo)
          this.$router.push({
            path: "/outpatient/status",
            query: {
              outTradeNo: this.outTradeNo
            }
          });
        }
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style scope>
.pay_container {
  font-size: 100px;
  color: rgb(13, 190, 13);
  font-weight: 700;
}

.pay_container div {
  position: fixed;
  top: 30%;
  left: 53%;
  transform: translate(-50%);
}
</style>